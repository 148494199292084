import * as React from "react";

import { Box, Typography, styled } from "@mui/material";

import show from "../../assets/show.png";
import zap from "../../assets/zap.png";
import Union from "../../assets/Union.png";

export default function CheckBox({ title, bgColor, bodyColor, subTitle3 }) {
  const StyledCard = styled(Box)({
    paddingRight: "32px",
  });

  return (
    <StyledCard>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          marginBottom: "27px",
        }}
      >
        <Typography
          sx={{
            display: { md: "block", sm: "none", xxs: "none" },
          }}
        >
          <img style={{ width: "18px" }} src={show} alt="show" />
        </Typography>

        <Typography
          sx={{
            fontSize: { xl: "16px", sm: "15px", md: "15px", xs: "15px", xxs: "15px" },
            color: "#3F434A",
          }}
        >
          {title === "Freelancer"
            ? "Be one click away when your clients are looking for someone"
            : "See all your freelancers and their availability at a glance"}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          marginBottom: "27px",
        }}
      >
        <Typography
          sx={{
            display: { md: "block", sm: "none", xxs: "none" },
          }}
        >
          <img style={{ width: "16px" }} src={zap} alt="zap" />
        </Typography>

        <Typography
          sx={{
            fontSize: { xl: "16px", sm: "15px", md: "15px", xs: "15px", xxs: "15px" },
            color: "#3F434A",
          }}
        >
          {title === "Freelancer"
            ? "Receive bookings straight to your mobile and calendar"
            : "Send bookings straight to their mobile for instant responses"}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          marginBottom: "27px",
        }}
      >
        <Typography
          sx={{
            display: { md: "block", sm: "none", xxs: "none" },
          }}
        >
          <img style={{ width: "18px" }} src={Union} alt="Union" />
        </Typography>

        <Typography
          sx={{
            fontSize: { xl: "16px", sm: "15px", md: "15px", xs: "15px", xxs: "15px" },
            color: "#3F434A",
          }}
        >
          {title === "Freelancer"
            ? "Share your availability and reduce the back and forth"
            : "Keep track of all your bookings in one clear schedule"}
        </Typography>
      </Box>
    </StyledCard>
  );
}
