import * as React from "react";
import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/Flxibl_logo.png";
import "./style.css";
import { Link } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  FormHelperText,
  MenuItem
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import useForm from "./useForm";
import { validator, validateAllError } from "./validator";
import { ValidateInviteToken } from "../Api";

export default function RegisterUser() {
  const currentUrl = window.location.pathname.split("/");
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const currencyList = [
    { key: 'AED', value: 'Dirhams (AED)' },
    { key: 'USD', value: 'Dollars ($)' },
    { key: 'GBP', value: 'Pounds (£)' },
    { key: 'EUR', value: 'Euros (€)' },
    { key: 'AUD', value: 'AU Dollars (AU$)' },

  ];

  const initState = {
    companyName: "",
    name: "",
    email: "",
    password: "",
    profession: "",
    phone: "",
    confirmPassword: "",
    currency: currencyList[1].key,
    termCondition: false,
    wordLimitExceeded: false,
    title: currentUrl[currentUrl.length - 1],
  };

  const [values, setValues] = React.useState({
    error: false,
    errorMsg: "",
    termCondition: false,
    isLoading: false,
    showPassword: false,
    showConfirmPassword: false,
    title: currentUrl[currentUrl.length - 1],
    windowHeight: window.innerHeight,
    invitationToken: params.__token ? params.__token : '',
  });

  const phoneTextFieldLabel = values.title === "company" ?
    "Whatsapp (Only visible to your freelancers)" :
    "Whatsapp (Only visible to your clients)";

  const { handleChange, handleBlur, state, setState, errors, setErrors } = useForm({
    initState,
    validator,
  });

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (window.location.origin.includes("https://www.flxibl.io")) {
      window.location.replace(
        "https://www.flxibl.com" + window.location.pathname
      );
    }
    if (params.__token) {
      ValidateInviteToken(params.__token, values, setValues, state, setState);
    }

    function handleWindowResize() {
      setValues((values) => {
        return ({
          ...values,
          windowHeight: window.innerHeight,
        })
      });
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const CustomTextField = (props) => {
    return (
      <TextField
        label={props.label}
        margin="normal"
        variant="standard"
        required
        fullWidth
        name={props.name}
        type={props.type}
        id={props.id}
        className="inputField"
        value={props.value}
        autoComplete="current-password"
        defaultValue={props.defaultValue}
        onBlur={handleBlur}
        onChange={handleChange}
        error={props.error}
        helperText={props.helperText}
        InputLabelProps={{
          shrink: true,
          style: { fontSize: 14, color: "#8A9099" },
        }}
        InputProps={{
          disableUnderline: true,
          autoComplete: "off",
          readOnly: (props.name === "email" && params.__token && !values.error) ? true : false,
          endAdornment: (props.name === "password" || props.name === "confirmPassword") && (
            <InputAdornment className="togglePassword" position="end">
              {props.name === "password" ? (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setValues({
                      ...values,
                      showPassword: !values.showPassword,
                    });
                  }}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ) : (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setValues({
                      ...values,
                      showConfirmPassword: !values.showConfirmPassword,
                    });
                  }}
                >
                  {values.showConfirmPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    );
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const body = {
      name: data.get('name').trim(),
      email: data.get('email'),
      profession: data.get('profession'),
      phone: data.get('phone'),
      password: data.get('password'),
      confirmPassword: data.get('confirmPassword'),
      currency: data.get('currency'),
      type: values.title,
      companyName: values.title === 'company' ? data.get('companyName').trim() : '',//this is used to check validation
      company_name: values.title === 'company' ? data.get('companyName').trim() : '',//this is used to for API
      termCondition: values.termCondition,//this is used to check validation
      token: params.__token ? params.__token : '',
      slug: sessionStorage.getItem('slug') ? `${sessionStorage.getItem('slug')}` : null,
    }
    let allEntered =
      data.get('name') !== "" &&
      data.get('email') !== "" &&
      data.get('profession') !== "" &&
      data.get('password') !== "" &&
      data.get('confirmPassword') !== "" &&

      values.termCondition &&
      Object.values(errors).every(el => el === undefined) &&
      (values.title === 'company' ? data.get('companyName') !== "" : true)

    if (allEntered) {
      setValues({ ...values, error: false, isLoading: true });
      axios.post(`${process.env.REACT_APP_ROUTE}/api/v1/registrations`, {
        user: body
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        mode: 'cors',
        credentials: 'include'
      })
        .then(function (response) {
          if (!response.data.error) {
            setValues({ ...values, error: false, errorMsg: '' });
            //   localStorage.setItem("color_code", response.data.user.color_code)
            if (response.data.existing_user) window.location = `/download-app?__email=${response.data.user.email}&__token=${response.data.confirmation_token}`
            // else if (values.title == "company") {
            //   window.location = `/verify-email`
            // }
            else {
              window.location = "/verify-email"
            }

          } else {
            setValues({ ...values, error: true, errorMsg: response.data.msg, isLoading: false });
          }
        })
        .catch(function (error) {
          setTimeout(
            () => setValues({ ...values, error: true, errorMsg: error.message, isLoading: false }),
            1200
          );
        });
    } else {
      let fieldArray = ['name', 'email', 'profession', 'password', 'confirmPassword', 'termCondition'];
      if (values.title === "company") fieldArray.push('companyName');
      validateAllError(fieldArray, body, setErrors);
    }
  };

  return (
    <>
      {values.error && (
        <Alert
          onClose={() => {
            setValues({ ...values, error: false });
          }}
          className="alert"
          variant="filled"
          severity="error"
        >
          {values.errorMsg}
        </Alert>
      )}
      
      <div className='signup-background'>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Link to={"/"} >
              <img className="mainLogo" src={logo} alt={"logo"} />
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to={"/login"} className="loginText">
              Login
            </Link>
          </Grid>
        </Grid>
        <Container component="main"
          sx={{
            maxWidth: {
              xxs: '90%',
              xs: "90%",
              sm: "480px",
              md: "480px",
              lg: "500px",
              xl: "520px"
            },
            marginTop: { sm: "20px", xxs: "20px" },
            paddingX: 0,
            marginBottom: "60px"
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: { sm: 8 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingX: { sm: 5, xs: 5, xxs: 3 },
              paddingY: { sm: 5, xxs: 5 },
              background: "white",
              borderRadius: { sm: 6, xxs: 6 },
              height: { xxs: "90%", sm: '90%' },
            }}
          >
            <Typography component="h1" variant="h4" color={"#3F434A"}>
              Create Account
            </Typography>
            <Box
              component="form"
              onSubmit={onSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              {values.title === "company" &&
                CustomTextField({
                  label: "Company Name",
                  id: "companyName",
                  name: "companyName",
                  defaultValue: state.companyName,
                  value: state.companyName,
                  error: errors.companyName ? true : false,
                  helperText: errors.companyName,
                })}

              {CustomTextField({
                label: "Full Name",
                id: "name",
                name: "name",
                defaultValue: state.name,
                value: state.name,
                error: errors.name ? true : false,
                helperText: errors.name,
              })}

              {CustomTextField({
                label: "Email",
                id: "email",
                name: "email",
                defaultValue: state.email,
                value: state.email,
                error: errors.email ? true : false,
                helperText: errors.email,
              })}

              {values.title === "freelancer" &&
                CustomTextField({
                  label: "Profession",
                  id: "profession",
                  name: "profession",
                  defaultValue: state.profession,
                  value: state.profession,
                  error: errors.profession ? true : false,
                  helperText: errors.profession,
                })
              }

              {
                CustomTextField({
                  label: phoneTextFieldLabel,
                  id: "phone",
                  name: "phone",
                  defaultValue: state.phone,
                  value: state.phone,
                  error: errors.phone ? true : false,
                  helperText: errors.phone,
                })
              }

              {values.title === "company" &&

                <TextField
                  select
                  id="currency"
                  name="currency"
                  value={state.currency}
                  onChange={handleChange}
                  className="regisBookingCurrency"
                  InputProps={{
                    disableUnderline: true,
                    style: { marginTop: '25px', marginBottom: '25px' }
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: 14, color: "#8A9099", marginTop: '17px', marginLeft: '-14px' },
                  }}
                  label="Currency"
                >
                  {currencyList.map((items, index) => (
                    <MenuItem
                      key={index}
                      value={items.key}
                      className="font-14"
                    >
                      {items.value}
                    </MenuItem>
                  ))}
                </TextField>
              }

              {CustomTextField({
                label: "Password",
                id: "password",
                name: "password",
                type: values.showPassword ? "text" : "password",
                defaultValue: state.password,
                value: state.password,
                error: errors.password ? true : false,
                helperText: errors.password,
              })}

              {CustomTextField({
                label: "Confirm Password",
                id: "confirmPassword",
                name: "confirmPassword",
                type: values.showConfirmPassword ? "text" : "password",
                defaultValue: state.confirmPassword,
                value: state.confirmPassword,
                error: errors.confirmPassword ? true : false,
                helperText: errors.confirmPassword,
              })}

              <FormControlLabel
                control={
                  <Checkbox
                    value="terms"
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                    onChange={() => {
                      setValues({ ...values, termCondition: !values.termCondition });
                      // setErrors((values) => {return ({...values, termCondition: values.termCondition})});
                    }}
                    color="primary"
                  />
                }
                label={
                  <Typography sx={{ color: '#595F69', fontSize: { sm: "14px", xxs: "12px" }, whiteSpace: "nowrap" }}>
                    I accept
                    <Button href="#text-buttons">
                      <Typography className="conditionText" sx={{ fontSize: { sm: "14px", xxs: "12px" } }}>
                        Terms and Conditions
                      </Typography>
                    </Button>
                  </Typography>
                }
                sx={{ marginTop: '10px' }}
              />
              {errors.termCondition === true &&
                <FormHelperText className="termsAndConditionsHelperText">Accept terms and conditions</FormHelperText>}
              <LoadingButton
                type="submit"
                fullWidth
                loading={values.isLoading}
                variant="contained"
                className="login-btn"
                sx={{ mt: 3, mb: 8 }}
              >
                Create Account
              </LoadingButton>

              <Grid container className='footerContainer'
                sx={{
                  position: { sm: 'relative', xxs: "relative" },
                  bottom: { sm: 0, xxs: 0 },
                  display: values.windowHeight <= 430 ? 'none' : 'flex'
                }}
              >
                <Button href="/login" className='font-14' sx={{ whiteSpace: "nowrap" }} >
                  <Typography className='signupText'>Already have an account?
                  </Typography>
                  <Typography sx={{ fontSize: { sm: "15px", xxs: "13px" }, fontWeight: '400', color: '#304FFD' }}>
                    Login
                  </Typography>
                </Button>
              </Grid>
            </Box>
          </Box>
        </Container>
      </div>
    </>
  );
}