import React from 'react';
import { Box, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Card, CardContent, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
export default function BookingDetailsDialog(props) {
  return (

    <Dialog
      className='agencyDialog'
      fullWidth={true}
      maxWidth={'md'}
      open={props.isDialogVisible}
      onClose={props.handleCancel}
    >
      <DialogTitle>

        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Typography className='agencyDialogTitle'>
              Booking Details
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <CloseIcon onClick={props.handleCancel} className='bookingCloseButton' color='action' />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {props.filterBooking !== null || props.filterBooking !== undefined ? props.filterBooking?.map((booking, id) => {
          return (<Card key={id} className='agencyBookingCard' sx={{ minWidth: 275 }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  {booking?.title && <Typography className='cardCompanyName'>
                    {booking?.title}
                  </Typography>
                  }
                  {booking?.title && <Typography className='managerName'>
                    {booking?.client_name}
                  </Typography>}

                </Grid>
                <Grid className='bookingDaysContainer' item xs={2}>
                  <Box className='bookingDays' style={{ backgroundColor: `${booking.color_code}` }} >
                    <Typography className='bookingText'>
                      <span className='bookingDaysCount'>{booking?.duration}</span><br />
                      <span className='bookingDaysText'>{booking?.duration > 1 ? "Days" : "Day"}</span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid className="dateTextContainer" item xs={12}>
                  <Typography className='dateText'>
                    {booking?.duration > 1 ? booking.booking_start_date === booking.booking_end_date ? `${booking.booking_start_date}, ${booking.booking_start_time} - ${booking.booking_end_time}` : `${booking.booking_start_date} - ${booking.booking_end_date}, ${booking.booking_start_time} - ${booking.booking_end_time}` :
                      `${booking.booking_start_date} , ${booking.booking_start_time} - ${booking.booking_end_time}`}
                  </Typography>
                </Grid>
                {booking?.project_name &&
                  <Grid className="projectNameContainer" item xs={12}>
                    <Typography className='projectNameText'>
                      {booking?.project_name}
                    </Typography>
                  </Grid>}
                {booking?.description &&
                  <Grid className="projectDescriptionContainer" item xs={12}>
                    <Typography className='projectDescriptionText'>
                      {booking?.description}
                    </Typography>
                  </Grid>
                }
                {props?.isAssocitedWithMint === false &&
                  <>
                    <Grid item xs={12}>
                      <Typography className='rateText'>
                        {`Rate per day: ${booking?.rate} ${booking?.currency}`}
                      </Typography>
                    </Grid>
                    <Grid className='totalRateContainer' item xs={12}>
                      <Typography className='totalRateText'>
                        {` Total: ${booking?.total_rate} ${booking?.currency}`}
                      </Typography>
                    </Grid>
                  </>}
                {booking?.location &&
                  <Grid item xs={12}>
                    <Typography className='location'>
                      {booking?.location}
                    </Typography>
                  </Grid>
                }
              </Grid>
            </CardContent>
          </Card>)
        }) : "No Booking To Show"}

      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>

  );
}