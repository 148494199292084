import * as React from 'react';
import { useEffect } from 'react';
import "./login.css"
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { login } from "../utils";
import { Link } from "react-router-dom";
import logo from "../../assets/Flxibl_logo.png";
import { Button, CssBaseline, TextField, Checkbox, Grid, Box, Typography, Container, IconButton, InputAdornment, Alert } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import "../../messaging_init_in_sw";

export default function SignIn() {
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    error: false,
    errorMsg: '',
    emailMsg: '',
    passwordMsg: '',
    rememberMe: true,
    isLoading: false,
    showPassword: false,
    windowHeight: window.innerHeight,
  });

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {

    const myvalue = localStorage.getItem('deviceToken')
    console.log('myvalue', myvalue);
    if (window.location.origin.includes('https://www.flxibl.io')) {
      window.location.replace("https://www.flxibl.com" + window.location.pathname);
    }

    function handleWindowResize() {
      setValues((values) => {
        return ({
          ...values,
          windowHeight: window.innerHeight,
        })
      });
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const onBlurField = (prop) => (event) => {
    handleValidation(prop, event.target.value);
  };

  const handleValidation = (fieldName, value) => {
    switch (fieldName) {
      case 'email':
        if (/.+@.+\.[A-Za-z]+$/.test(value)) {
          setValues((values) => {
            return ({
              ...values,
              email: value,
              emailMsg: '',
            })
          });
        }
        else {
          setValues((values) => {
            return ({
              ...values,
              email: value,
              emailMsg: value === '' ? 'Email is blank' : 'Invalid email',
            })
          });
        }
        break;
      case 'password':
        if (value.length > 0) {
          setValues((values) => {
            return ({
              ...values,
              password: value,
              passwordMsg: '',
            })
          });
        }
        else {
          setValues((values) => {
            return ({
              ...values,
              password: value,
              passwordMsg: 'Password is blank',
            })
          });
        }
        break;
      default:
    }
  }

  const handleRedirection = (props) => {
    switch (props.data.user.user_type) {
      case 'Firm': // it means Company
        if (props.data.user.is_approved === true) {
          axios.post(`${process.env.REACT_APP_ROUTE}/set_token/${props.data.user.id}`, {
            device_token: localStorage.getItem('deviceToken'),
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            mode: 'cors',
            credentials: 'include'
          }).then(function (response) {  // eslint-disable-line no-unused-vars
          }).catch(function (error) {
            console.log("device token error", error)
          })

          localStorage.setItem("color_code", props.data.user.color_code)
          localStorage.setItem("user_type", props.data.user.user_type)
          localStorage.setItem("resource_id", props.data.user.id)
          localStorage.setItem("phone", props.data.user.phone)
          localStorage.setItem("agency", props.data.user.agency)
          localStorage.setItem("email", props.data.user.email)
          localStorage.setItem("invitation_count", props.data.user.invitation_count);
          login(props.data.user.token, values.rememberMe)
          window.location = "/"
        }
        else if (props.data.user.user_type === "Firm" && props.data.user.is_approved === false) {
          window.location = `/email-confirmation?__email=${props.data.user.email}&__token=${props.data.user.confirmation_token}`;
        }
        break;
      case 'Company': // it means Agency
        if (window.innerWidth > 900) {
          localStorage.setItem("color_code", props.data.user.color_code)
          localStorage.setItem("user_type", props.data.user.user_type)
          localStorage.setItem("resource_id", props.data.user.id)
          localStorage.setItem("phone", props.data.user.phone)
          localStorage.setItem("agency", props.data.user.agency)
          localStorage.setItem("email", props.data.user.email)
          localStorage.setItem("invitation_count", props.data.user.invitation_count);
          login(props.data.user.token, values.rememberMe)
          window.location = "/"
        }
        else if (props.data.user.user_type === "Company") {
          window.location = `/email-confirmation?__email=${props.data.user.email}&__token=${props.data.user.confirmation_token}`
        }
        break;
      case 'User': // it means Freelancer
      case 'Freelancer':
        window.location = `/download-app?__email=${props.data.user.email}&__token=${props.data.user.confirmation_token}`
        break;
      default:
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    let allEntered = data.get('email') !== "" && data.get('password') !== "";
    let noError = values.emailMsg === "" && values.passwordMsg === "";

    if (allEntered && noError) {
      setValues({ ...values, error: false, isLoading: true });
      axios.post(`${process.env.REACT_APP_ROUTE}/users/sign_in`, {
        email: data.get('email'),
        password: data.get('password'),
        slug: sessionStorage.getItem('slug') ? `${sessionStorage.getItem('slug')}` : null,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        mode: 'cors',
        credentials: 'include'
      })
        .then(function (response) {
          if (response.data.user.error === false) {
            setValues({ ...values, error: false, errorMsg: '' })
            handleRedirection(response);
          } else {
            setValues({ ...values, error: true, errorMsg: response.data.user.msg, isLoading: false });
            if (response.data.user.msg.startsWith("Error:")) {
              setTimeout(() => handleRedirection(response), 5000);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          setTimeout(
            () => setValues({ ...values, error: true, errorMsg: error.message, isLoading: false }),
            1200
          );
        });
    } else {
      handleValidation('email', data.get('email'));
      handleValidation('password', data.get('password'));
    }
  };

  return (
    <>
      {values.error && <Alert onClose={() => { setValues({ ...values, error: false }) }} className="alert" variant="filled" severity="error">
        {values.errorMsg}
      </Alert>}
      <div class="login-background" style={{ display: "block", height: "102.7vh" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Link to={"/"} >
              <img className="mainLogo" src={logo} alt={"logo"} />
            </Link>
          </Grid>
          <Grid item xs={6}>
            <Link to={"/signup"} className="loginText">
              Sign Up
            </Link>
          </Grid>
        </Grid>
        <Container component="main"
          sx={{
            maxWidth: {
              xxs: '90%',
              xs: "90%",
              sm: "480px",
              md: "480px",
              lg: "500px",
              xl: "520px"
            },
            display: { xs: "flex", xxs: "flex" },
            justifyContent: { xs: "center", xxs: "center" },
            flexDirection: { xs: "column", xxs: "column" },
            paddingX: 0,
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: { sm: 8 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingX: { sm: 5, xs: 5, xxs: 3 },
              paddingY: { sm: 5, xxs: 5 },
              background: "white",
              borderRadius: { sm: 6, xs: 6, xxs: 6 },
            }}
          >
            <Typography align='center' component="h1" variant="h4" color={"#3F434A"}>
              Login To Your Account
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                label="Email"
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                autoComplete="username"
                className="email"
                helperText={values.emailMsg}
                // onChange={handleChange('email')}
                onBlur={onBlurField('email')}
                InputProps={{ disableUnderline: true, autoComplete: "off" }}
                InputLabelProps={{ shrink: true, style: { fontSize: 14, color: "#8A9099" } }}
              />

              <TextField
                label="Password"
                margin="normal"
                variant="standard"
                required
                fullWidth
                name="password"
                type={values.showPassword ? 'text' : 'password'}
                // onChange={handleChange('password')}
                onBlur={onBlurField('password')}
                id="password"
                className="password"
                autoComplete="current-password"
                helperText={values.passwordMsg}
                InputLabelProps={{ shrink: true, style: { fontSize: 14, color: "#8A9099" } }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment className='togglePassword' position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => { setValues({ ...values, showPassword: !values.showPassword }) }}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Box sx={{ direction: 'row', display: { sm: 'flex', md: 'flex' }, justifyContent: 'space-between', textAlign: { xs: "center" } }}>
                <Box sx={{ ml: -2, direction: 'row', display: { sm: 'flex', md: 'flex' }, alignItems: 'center' }}>
                  <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} style={{ display: "inline" }} checked={values.rememberMe ? true : false} value="remember" onChange={() => { setValues({ ...values, rememberMe: !values.rememberMe }) }} color="primary" />
                  <Typography fontSize={14} sx={{ color: '#595F69', display: "inline" }}>Keep me logged in</Typography>
                </Box>
                <Button className='forgotPassowrd' style={{ right: { sm: 0 } }}><Link to="forgot-password" className='forgotPassowrd'>Forgot Password?</Link></Button>

              </Box>

              <LoadingButton
                type="submit"
                fullWidth
                loading={values.isLoading}
                variant="contained"
                className='login-btn'
                sx={{ mt: 3, mb: 2 }}
              >
                Login
              </LoadingButton>
              <Grid container className='footerContainer'
                sx={{
                  position: { sm: 'relative', xxs: "fixed" },
                  // backgroundColor: { xxs: '#fff', sm: 'transparent' },
                  bottom: { sm: 0, xxs: "10px" },
                  display: values.windowHeight <= 430 ? 'none' : 'flex'
                }}
              >
                <Button href="/signup" className='font-14'>
                  <Typography className='signupText' style={{ color: "#3F434A" }}>Don't have an account? </Typography>
                  <Typography sx={{ fontSize: '15px', fontWeight: '400', color: '#304FFD' }}>
                    Sign Up
                  </Typography>
                </Button>
              </Grid>
            </Box>
          </Box>
        </Container>
      </div>
    </>
  );
}