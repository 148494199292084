import React from 'react'
import WhatsAppWidget from 'react-whatsapp-widget'
import "./index.css"
const WhatsApp = ({ number, click, open }) => {
  return (
    <div id="whatsapp-button" onClick={click} >
      <div id="whatsapp-btn">
        <WhatsAppWidget open={open} phoneNumber={number} />
      </div>
    </div >
  )
}
export default WhatsApp