import { useState, useEffect } from "react";

const useForm = ({ initState, callback, validator }) => {
  var words = 0;
  const wordsLimit = 3;
  const [state, setState] = useState(initState);
  const [errors, setErrors] = useState({});
  const [isSubmited, setIsSubmited] = useState(false);

  useEffect(() => {
    const isValidErrors = () =>
      Object.values(errors).filter(error => typeof error !== "undefined")
        .length > 0;
    if (isSubmited && !isValidErrors()) callback();
    if (state.wordLimitExceeded){
      const faildField = validator(state, 'profession');
      setErrors(() => ({
        ...errors,
        'profession': Object.values(faildField)[0]
      }));
    }
  }, [state.wordLimitExceeded]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === "profession") {
      wordMaxLimit(e, wordsLimit);
    } else {
      setState(() => ({
        ...state,
        [name]: value,
      }));
      if (state.status === "pending" || state.status === "accepted" || state.status === "optional") {
        setState(() => ({
          ...state,
          [name]: value,
          isUpdated: true,
        }));
      }
    }
  };

  const wordMaxLimit = (e, limit) => {
    words = e.target.value?.length ? e.target.value?.match(/\S+/g)?.length : 0;
    e.target.value = e.target.value?.endsWith(" ") ? e.target.value?.trim() + " " : e.target.value?.trim();

    if (words === limit && e.target.value?.endsWith(" ")) {
      setState((state) => ({
        ...state,
        wordLimitExceeded: true
      }));
      e.target.value?.trim();
    }
    else if (words <= limit) {
      setState((state) => ({
        ...state,
        wordLimitExceeded: false,
        [e.target.name]: e.target.value
      }));
    }
  }

  const handleBlur = e => {
    const { name: fieldName } = e.target;
    const faildFiels = validator(state, fieldName);
    setErrors(() => ({
      ...errors,
      [fieldName]: (fieldName === "profession" && state.wordLimitExceeded) ? "" : Object.values(faildFiels)[0]
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();

    const { name: fieldName } = e.target;
    const faildFiels = validator(state, fieldName);
    setErrors(() => {
      return ({
        ...errors,
        name: Object.values(faildFiels)[0]
      })
    });
    setIsSubmited(true);
  };

  return {
    handleChange,
    handleSubmit,
    handleBlur,
    state,
    setState,
    errors,
    setErrors,
  };
};

export default useForm;