import * as React from "react";
import axios from 'axios';
import { EmailConfirmation, getToken } from "./utils";
import * as Sentry from "@sentry/react";

export function ConfirmationEmailApi(comUrl, url, values, setValues, setIsEmailConfirmed) {
  const urlSearchParams = new URLSearchParams(url);
  const params = Object.fromEntries(urlSearchParams.entries());

  axios.post(`${process.env.REACT_APP_ROUTE}/api/v1/email_confirmations`, {
    email: params.__email,
    token: params.__token
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    mode: 'cors',
    credentials: 'include'
  })
    .then(function (response) {
      setValues((values) => {
        return ({
          ...values,
          error: response.data.error,
          errorMsg: response.data.msg,
          alreadyVerified: response.data.already_confirmed,
          showAlert: true,
          title: response.data.error ? React.createElement('h1', {}, 'Sorry') : React.createElement('h1', {}, 'Thank You'),
          body:
            !response.data.error ?
              response.data.already_confirmed ?
                (React.createElement('p', { className: 'invitaitionCardBody', id: 'invitaitionCardBody' }, 'Your email is already verified. You will be redirected to your dashboard shortly or ',
                  React.createElement('a', { href: '/', className: 'invitaitionCardLink', id: 'invitaitionCardLink' }, 'click here'), " to go to your dashboard.",
                ))
                :
                (React.createElement('p', { className: 'invitaitionCardBody', id: 'invitaitionCardBody' }, 'Your email is verified. You will be redirected to your dashboard shortly or ',
                  React.createElement('a', { href: '/', className: 'invitaitionCardLink', id: 'invitaitionCardLink' }, 'click here'), " to go to your dashboard.",
                ))
              :
              (React.createElement('p', { className: 'invitaitionCardBody', id: 'invitaitionCardBody' }, 'Your email could not be verified. Please try clicking the verification link sent to your email again or contact support at ',
                React.createElement('span', { className: 'invitaitionCardLink', id: 'invitaitionCardLink' }, 'support@flxibl.com.'),
              ))
        })
      });
      if (comUrl !== '/download-app')
        setIsEmailConfirmed(true);
    })
    .catch(function (error) {
      Sentry.captureException(error);
      setValues({ ...values, error: true, errorMsg: error.message, showAlert: true });
    });
}

export function ConfirmationUserApi(comUrl, url, values, setValues) {
  const urlSearchParams = new URLSearchParams(url);
  const params = Object.fromEntries(urlSearchParams.entries());

  axios.post(`${process.env.REACT_APP_ROUTE}/api/v1/email_confirmations`, {
    email: params.__email,
    token: params.__token,
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    mode: 'cors',
    credentials: 'include'
  })
    .then(function (response) {
      setValues((values) => {
        return ({
          ...values,
          error: response.data.error,
          errorMsg: response.data.msg,
          alreadyVerified: response.data.already_confirmed,
          showAlert: true,
          is_approved: response.data.is_approved,
          title: response.data.error ? React.createElement('h1', {}, 'Sorry') : response.data.is_approved ? React.createElement('h1', {}, 'Sucessfully Approved') : React.createElement('h1', {}, 'You’re in the queue..'),
          body:
            !response.data.error ?
              response.data.is_approved ?
                (React.createElement('p', { className: 'invitaitionCardBody', id: 'invitaitionCardBody' }, 'Your account got approved successfully. You will be redirected to login shortly.',
                  // React.createElement('a', { href: '/login', className: 'invitaitionCardLink', id: 'invitaitionCardLink' }, 'Click here'), " to go to login page.",
                ))
                :
                (React.createElement('p', { className: 'invitaitionCardBody', id: 'invitaitionCardBody' }, 'Thanks for your interest in Flxibl. Due to exceptionally high demand, we’ve had to temporarily limit the number of companies that can sign up. Someone from our team will review your account and email you directly if we’re able to grant you exclusive first access. ',
                  // React.createElement('a', { href: '/login', className: 'invitaitionCardLink', id: 'invitaitionCardLink' }, 'Click here'), " to go to login page.",
                  React.createElement('p', { className: 'invitaitionCardBody innerParagraph' }, 'Thanks for your patience, it’ll be worth the wait! '), "",
                ))
              :
              (React.createElement('p', { className: 'invitaitionCardBody', id: 'invitaitionCardBody' }, 'Your account could not be approved. Please try clicking the verification link sent to your email again or contact support at ',
                React.createElement('span', { className: 'invitaitionCardLink', id: 'invitaitionCardLink' }, 'support@flxibl.com.'),
              ))
        })
      });
      // if (comUrl != '/download-app')
      //   setIsUserConfirmed(true);
    })
    .catch(function (error) {
      Sentry.captureException(error);
      setValues({ ...values, error: true, errorMsg: error.message, showAlert: true });
    });
}


export function CompanyJWT(url, values, setValues) {
  const urlSearchParams = new URLSearchParams(url);
  const params = Object.fromEntries(urlSearchParams.entries());
  localStorage.removeItem("isEmailConfirmed");

  axios.post(`${process.env.REACT_APP_ROUTE}/api/v1/get_company_jwt`, {
    email: params.__email
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    mode: 'cors',
    credentials: 'include'
  })
    .then(function (response) {
      if (response.data.error === false) {
        localStorage.setItem("color_code", response.data.user.color_code);
        localStorage.setItem("user_type", response.data.user.user_type);
        localStorage.setItem("resource_id", response.data.user.id);
        localStorage.setItem("phone", response.data.user.phone);
        localStorage.setItem("agency", response.data.user.agency);
        localStorage.setItem("email", response.data.user.email);
        localStorage.setItem("invitation_count", response.data.user.invitation_count);
        EmailConfirmation(response.data.user.is_confirmed, response.data.user.token);
      } else {
        setValues((values) => {
          return ({ ...values, error: response.data.error });
        });
      }
    })
    .catch(function (error) {
      console.log("---Compnay JWT Error---", error);
    });
}
const getClickableLink = (link) => {
  return link.startsWith("http://") || link.startsWith("https://") ?
    link
    : `http://${link}`;
}

export function sendInviteApi(data, values, setValues, form) {
  const body = {
    email: data.get('email').toLowerCase(),
    role: data.get('profession').trim(),
    portfolio_link: data.get('link') ? getClickableLink(data.get('link')?.trim()) : "",
  }
  axios.post(`${process.env.REACT_APP_ROUTE}/invitations`, { invite: body }, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    mode: 'cors',
    credentials: 'include'
  })
    .then(function ({ data }) {
      setValues({ ...values, error: data.error, showAlert: true, msg: data.msg });
      form?.reset();
    })
    .catch(function (error) {
      Sentry.captureException(error);
      form?.reset();
      setTimeout(
        () => setValues({ ...values, error: true, msg: error.message, isLoading: false }),
        1200
      );
    });
}

export function UpdateInviteApi(data, values, setValues, fId) {
  const body = {
    email: data.email,
    profession: data.profession.trim(),
    portfolio_link: data.portfolio_link ? getClickableLink(data.portfolio_link?.trim()) : "",
  }
  axios.put(`${process.env.REACT_APP_ROUTE}/update_invite/${fId}`, { invite: body }, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    mode: 'cors',
    credentials: 'include'
  })
    .then(function ({ data }) {
      setValues({ ...values, error: data.error, showAlert: true, msg: data.msg });
    })
    .catch(function (error) {
      Sentry.captureException(error);
      setTimeout(
        () => setValues({ ...values, error: true, msg: error.message, isLoading: false }),
        1200
      );
    });
}

export function AcceptInviteApi(url, values, setValues) {
  const urlSearchParams = new URLSearchParams(url);
  const params = Object.fromEntries(urlSearchParams.entries());
  axios.patch(`${process.env.REACT_APP_ROUTE}/invitations/${params.__token}`, {
    invite: {
      status: 'accepted'
    }
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    mode: 'cors',
    credentials: 'include'
  })
    .then(function (response) {
      setValues((values) => {
        return ({
          ...values,
          error: response.data.error,
          errorMsg: response.data.msg,
          showAlert: true,
          title: response.data.error ? React.createElement('h1', {}, 'Sorry') : React.createElement('h1', {}, 'Thank You'),
          body:
            !response.data.error ?
              (React.createElement('p', { className: 'invitaitionCardBody', id: 'invitaitionCardBody' }, 'Looks like you have accepted the invite. You will be redirected to your signup shortly or ',
                React.createElement('a', { href: `/signup/freelancer?__token=${params.__token}`, className: 'invitaitionCardLink', id: 'invitaitionCardLink' }, 'click here'), " to go to Signup.",
              ))
              :
              (React.createElement('p', { className: 'invitaitionCardBody', id: 'invitaitionCardBody' }, 'Your invitation could not be verified. Please try again by clicking the invitation link sent to your email or contact support at ',
                React.createElement('span', { className: 'invitaitionCardLink', id: 'invitaitionCardLink' }, 'support@flxibl.com.'),
              ))
        })
      });
    })
    .catch(function (error) {
      Sentry.captureException(error);
      setValues({ ...values, error: true, errorMsg: error.message, showAlert: true });
    });
}

export function GetInvitesApi(values, setValues) {
  axios.get(`${process.env.REACT_APP_ROUTE}/invitations`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + getToken()
      },
      mode: 'cors',
      credentials: 'include'
    })
    .then(function (response) {
      setValues((values) => {
        return ({
          ...values,
          error: response.data.error,
          invitations: response.data.invitations,
          total: response.data.count,
        })
      });
    })
    .catch(function (error) {
      Sentry.captureException(error);
      setValues({ ...values, error: true, errorMsg: error.message, showAlert: true });
    });
}

export function ValidateInviteToken(token, values, setValues, state, setState) {
  axios.post(`${process.env.REACT_APP_ROUTE}/validate_invite_token`, { token: token }, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    mode: 'cors',
    credentials: 'include'
  })
    .then(function ({ data }) {
      if (!data.error) {
        setState(() => ({
          ...state,
          email: data.email,
          // ['profession']: data.profession,
        }));
      } else {
        setValues({ ...values, error: data.error, showAlert: true, errorMsg: data.msg });
      }
    })
    .catch(function (error) {
      Sentry.captureException(error);
      setTimeout(
        () => setValues({ ...values, error: true, showAlert: true, errorMsg: error.message }),
        1200
      );
    });
}

export function GetNotificationListApi(values, setValues, handleNewNotificationsCount, handleNewNotificationArrived) {
  axios.get(`${process.env.REACT_APP_ROUTE}/notifications`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + getToken()
      },
      mode: 'cors',
      credentials: 'include'
    })
    .then(function (response) {
      // handleNewNotificationsCount(response.data.new_notifications_count);
      if (response.data.new_notifications_count > 0)
        handleNewNotificationArrived(true);
      setValues((values) => {
        return ({
          ...values,
          error: response.data.error,
          notifications: response.data.notifications,
          total: response.data.total,
          newNotificationsCount: response.data.new_notifications_count,
        })
      });
    })
    .catch(function (error) {
      Sentry.captureException(error);
      setValues({ ...values, error: true, errorMsg: error.message, showAlert: true });
    });
}

export function DeleteNotificationApi(id, values, setValues) {
  axios.delete(`${process.env.REACT_APP_ROUTE}/notifications/${id}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + getToken()
      },
      mode: 'cors',
      credentials: 'include'
    })
    .then(function (response) {
      setValues((values) => {
        return ({
          ...values,
          error: response.data.error,
          errorMsg: response.data.msg,
        })
      });
    })
    .catch(function (error) {
      Sentry.captureException(error);
      setValues({ ...values, error: true, errorMsg: error.message, showAlert: true });
    });
}

export function MarkReadToNotificationsApi(values, setValues, handleNewNotificationsCount) {
  var id = localStorage.getItem("resource_id");
  axios.patch(`${process.env.REACT_APP_ROUTE}/notifications/${id}`, {
    invite: {
      status: 'accepted'
    }
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    mode: 'cors',
    credentials: 'include'
  })
    .then(function (response) {
      handleNewNotificationsCount(0);
      if (response.data.error) {
        setValues((values) => {
          return ({ ...values, error: response.data.error });
        });
      }
    })
    .catch(function (error) {
      Sentry.captureException(error);
      setValues({ ...values, error: true, errorMsg: error.message, showAlert: true });
    });
}

export function AddFreelancerToComapany(values, setValues, slug) {
  axios.get(`${process.env.REACT_APP_ROUTE}/shareable_link/${slug}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + getToken()
    },
    mode: 'cors',
    credentials: 'include'
  })
    .then(function (response) {
      setValues((values) => {
        return ({
          ...values,
          error: response.data.error,
          errorMsg: response.data.msg,
          showAlert: true,
          title: response.data.error ? React.createElement('h1', {}, 'Sorry') : React.createElement('h1', {}, 'Thank You'),
          body:
            !response.data.error ?

              (React.createElement('p', { className: 'invitaitionCardBody', id: 'invitaitionCardBody' }, 'The Freelancer has been added. You will be redirected to your dashboard shortly or ',
                React.createElement('a', { href: '/', className: 'invitaitionCardLink', id: 'invitaitionCardLink' }, 'click here'), " to go to your dashboard.",
              ))

              :
              (React.createElement('p', { className: 'invitaitionCardBody', id: 'invitaitionCardBody' }, 'Something went Wrong. Please come again from the shareable link or ',
                React.createElement('a', { href: '/signup/company', className: 'invitaitionCardLink', id: 'invitaitionCardLink' }, 'click here'), " to go to the home page or contact support at ",
                React.createElement('span', { className: 'invitaitionCardLink', id: 'invitaitionCardLink' }, 'support@flxibl.com.'),
              ))
        })
      });
    })
    .catch(function (error) {
      Sentry.captureException(error);
      setValues({ ...values, error: true, errorMsg: error.message, showAlert: true });
    });
}