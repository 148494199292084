import "./App.css";
import * as React from "react";

import { BrowserRouter, Switch } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import DownloadApp from "./components/DownloadApp";
import ShareAbleLink from "./components/ShareAbleLink/index";
import WaitingForApproval from "./components/WaitingForApproval/index";
import ForgotPassword from "./components/ForgotPassword/index";
import ConfirmPassword from "./components/ConfirmPassword/index";
import Login from "./components/Login";
import Privacy from "./components/PrivacyAndpolicy/index";
import PrivateRoute from "./components/routes/PrivateRoute";
import PublicRoute from "./components/routes/PublicRoute";
import RegisterUser from "./components/Register/registerUser";
import SignUp from "./components/SignUp";
import Support from "./components/support/index";
import VerifyEmail from "./components/VerifyEmail";
import CompanyBrowser from "./components/RedirectAfterEmail";
import Invite from "./components/Invite";
import LandingScreen from "./components/LandingScreen/index";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga";

import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_ANALYTIC
}

class App extends React.Component {

  componentDidMount() {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTIC);
    ReactGA.pageview(window.location.pathname);
    TagManager.initialize(tagManagerArgs);
    window.dataLayer.push({
      event: 'pageview'
    });
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <PrivateRoute component={Dashboard} path="/" exact />
          <PublicRoute component={LandingScreen} path="/landing" exact />
          <PublicRoute component={Support} path="/support" exact />
          <PublicRoute component={Privacy} path="/privacy-policy" exact />
          <PublicRoute
            restricted={true}
            component={Login}
            path="/login"
            exact
          />
          <PublicRoute component={SignUp} path="/signup" exact />
          <PublicRoute
            restricted={true}
            component={RegisterUser}
            path="/signup/freelancer"
            exact
          />
          <PublicRoute
            restricted={true}
            component={RegisterUser}
            path="/signup/company"
            exact
          />
          <PublicRoute component={VerifyEmail} path="/verify-email" exact />
          <PublicRoute component={DownloadApp} path="/download-app" exact />
          <PublicRoute
            component={CompanyBrowser}
            path="/email-confirmation"
            exact
          />
          <PublicRoute component={Invite} path="/invite" exact />
          <PublicRoute
            component={ShareAbleLink}
            path="/freelancer/:slug"
            exact
          />
          <PublicRoute
            component={WaitingForApproval}
            path="/waiting-approval"
            exact
          />
          <PublicRoute
            component={ForgotPassword}
            path="/forgot-password"
            exact
          />
          <PublicRoute
            component={ConfirmPassword}
            path="/confirm-password"
            exact
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Sentry.withProfiler(App);