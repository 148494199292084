import * as React from "react";
import { useEffect } from "react";
import {
  Box,
  Container,
  CssBaseline,
  Link,
  Typography,
  Alert,
} from "@mui/material";
import 'react-whatsapp-widget/dist/index.css'
import WhatsApp from '../Dashboard/WhatsApp'
import { ConfirmationUserApi } from "../Api";

export default function WaitingForApproval() {
  const [values, setValues] = React.useState({
    error: false,
    errorMsg: "",
    showAlert: false,
    alreadyVerified: false,
    is_approved: false,
    title: "",
    body: '',
  });
  // const valid = document.referrer.includes("signup/company");
  React.useLayoutEffect(() => {
    // if (!document.referrer.includes("signup/company")) {
    //   window.location = "/login";
    // }
    values.title = React.createElement("h1", {}, "");
    values.body = React.createElement("p", {}, "");
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (window.location.origin.includes("https://www.flxibl.io")) {
      window.location.replace(
        "https://www.flxibl.com" + window.location.pathname
      );
    }
    ConfirmationUserApi(window.location.pathname, window.location.search, values, setValues);
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (values.is_approved && values.error === false && window.innerWidth > 900) {
      setTimeout(() => {
        window.location = "/login";
      }, [5000]);
    }
  }, [values.is_approved, values.error]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='signup-background'>
      {
        values?.showAlert && (
          <Alert
            onClose={() => {
              setValues({ ...values, showAlert: false });
            }}
            className="alert"
            variant="filled"
            severity={values?.error ? "error" : "success"}
          >
            {values?.errorMsg}
          </Alert>
        )
      }

      < Container
        component="main"
        className="cardContainer"
        sx={{
          width: { md: "600px", xl: "650px", sm: "90%", xxs: "100%" },
          maxWidth: {
            xxs: '100%',
            xs: "100%",
            sm: "480px",
            md: "480px",
            lg: "650px",
            xl: "650px"
          },
        }}

      >
        <CssBaseline />
        <Box className="cardBox" sx={{ paddingX: "25px" }}>
          <Typography
            component="h1"
            variant="h4"
            className="cardHeader"
            sx={{
              pt: { sm: "91px", xxs: 0 },
              pb: "36px",
              width: { sm: "100%", xxs: '80%' }
            }}
          >
            {values.title}
          </Typography>
          <Typography
            component="h1"
            variant="h6"
            className="cardDescription"
            sx={{
              pb: { md: "100px", xxs: "47px" },
              paddingX: "20px"
            }}
          >
            {values.body}
          </Typography>

          <Box
            item
            sx={{
              position: { sm: "relative", xxs: "fixed" },
              bottom: { sm: "20px", xxs: "30px" },
              mt: { xxs: 5, md: 0 },
            }}
            className="cardFooter"
          >
            {"Go back to "}
            <Link
              href="/landing"
              variant="body2"
              className="cardLink"
            >
              {"Home"}
            </Link>
          </Box>
        </Box>
      </Container >
      <WhatsApp number={process.env.REACT_APP_FLXIBLE_SUPPORT} />
    </div >

  );
}
