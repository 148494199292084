import * as React from 'react';
import { Grid, Skeleton } from "@mui/material";

export default function CustomSkeleton() {
  return (
    <Grid container className='customSkeleton' rowSpacing={1} columnSpacing={0.5}>
      {
        Array.from(new Array(6)).map(() => (
          <>
            <Grid className='skeletonLefColumn' item xs={2}><Skeleton className='skeletonColor' animation="wave" variant="rectangular" height={80} /></Grid>
            {Array.from(new Array(12)).map((item, i) => (
              <Grid key={i} className='skeletonRightColumn' item xs={1}>
                <Skeleton className='skeletonColor' animation="wave" variant="rectangular" height={80} />
              </Grid>
            ))}
          </>
        ))}
    </Grid>
  );
}