import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

function requestPermission() {
  console.log("Requesting permission...");


  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSANGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };

  if ('Notification' in window) {
    try {
      Notification.requestPermission()
        .then((permission) => {


          if (permission === "granted") {
            console.log("Notification permission granted.");
            // debugger
            const app = initializeApp(firebaseConfig);
            const messaging = getMessaging(app);

            // onMessage(messaging, (payload) => {
            //   console.log('Message received. ', payload);        
            // });

            getToken(messaging, {
              vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
            }).then((currentToken) => {
              if (currentToken) {
                localStorage.setItem("deviceToken", currentToken)
                console.log("token=================================", currentToken);
              } else {
                console.log("currentToken not genrated");
              }
            });
          } else {
            console.log("Notification permission  Not granted.");
          }
        });
    } catch (error) {
      // Safari doesn't return a promise for requestPermissions and it                                                                                                                                       
      // throws a TypeError. It takes a callback as the first argument                                                                                                                                       
      // instead.
      if (error instanceof TypeError || error === undefined) {
        Notification.requestPermission((permission) => {
          if (permission === "granted") {
            console.log("Notification permission granted.");
            // debugger
            const app = initializeApp(firebaseConfig);
            const messaging = getMessaging(app);

            // onMessage(messaging, (payload) => {
            //   console.log('Message received. ', payload);        
            // });

            getToken(messaging, {
              vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
            }).then((currentToken) => {
              if (currentToken) {
                localStorage.setItem("deviceToken", currentToken)
                console.log("token=================================", currentToken);
              } else {
                console.log("currentToken not genrated");
              }
            });
          } else {
            console.log("Notification permission  Not granted.");
          }
        });
      } else {
        throw error;
      }
    }
  } else {
    console.log("NOTIFICATION API NOT SUPPORTED")
    // API not supported
  }




}

requestPermission();
