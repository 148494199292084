import * as React from "react";
import { useEffect } from 'react';
import { Box, Container, CssBaseline, Link, Typography, Alert } from "@mui/material";
import { ConfirmationEmailApi, CompanyJWT } from "../Api";

export default function CompanyBrowser() {
  const [values, setValues] = React.useState({
    error: null,
    errorMsg: '',
    showAlert: false,
    alreadyVerified: false,
    title: '',
    body: '',
  });
  let isEmailConfirmed = React.useRef(false);

  React.useLayoutEffect(() => {
    values.title = React.createElement('h1', {}, '');
    values.body = React.createElement('p', {}, '');
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (window.location.origin.includes('https://www.flxibl.io')) {
      window.location.replace("https://www.flxibl.com" + window.location.pathname);
    }
    ConfirmationEmailApi(window.location.pathname, window.location.search, values, setValues, setIsEmailConfirmed);
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEmailConfirmed && !values.error && window.innerWidth > 900) {
      CompanyJWT(window.location.search, values, setValues);
      setTimeout(() => {
        window.location = "/";
      }, [5000]);
    }
  }, [values.isEmailConfirmed, values.error]);  // eslint-disable-line react-hooks/exhaustive-deps

  const setIsEmailConfirmed = (status) => {
    isEmailConfirmed.current = status;
  }

  return (
    <>
      {values?.showAlert && <Alert onClose={() => { setValues({ ...values, showAlert: false }) }} className="alert" variant="filled" severity={values?.error ? "error" : "success"}>
        {values?.errorMsg}
      </Alert>}

      <Container
        component="main"
        className='cardContainer'
        sx={{
          width: { md: "600px", xl: "600px", sm: "90%", xxs: "100%" },
          maxWidth: {
            xxs: '100%',
            xs: "100%",
            sm: "480px",
            md: "480px",
            lg: "500px",
            xl: "520px"
          },
        }}
      >
        <CssBaseline />
        <Box className="cardBox" sx={{ paddingX: "25px" }}>
          <Typography
            component="h1"
            variant="h4"
            className="cardHeader"
            sx={{
              pt: { sm: "91px", xxs: 0 },
              display: { md: "none" },
              pb: "36px",
              width: { md: "100%", sm: "80%", xxs: "90%" },
            }}
          >
            You’re in the queue..
          </Typography>
          <Typography
            component="h1"
            variant="h6"
            className="cardDescription"
            sx={{
              display: { md: "none" },
              pb: { md: "100px", xxs: "47px" },
              paddingX: "20px",
            }}
          >
            Thanks for your interest in Flxibl. Due to exceptionally high demand, we’ve had to temporarily limit the number of companies that can sign up. Someone from our team will review your account and email you directly if we’re able to grant you exclusive first access.
          </Typography>
          <Typography
            component="p"
            variant="p"
            className="cardDescription"
            sx={{
              display: { md: "none" },
              pb: { md: "100px", xxs: "47px" },
              paddingX: "20px",
            }}
          >
            Thanks for your patience, it’ll be worth the wait!
          </Typography>
          <Typography
            component="h1"
            variant="h4"
            className="cardHeader"
            sx={{
              pt: { sm: "51px", xxs: 0 },
              pb: "36px",
              display: { md: "block", xxs: 'none' },
              width: { md: '100%', sm: "80%", xxs: '90%' },
            }}
          >
            {values.title}
          </Typography>

          <Box sx={{ paddingBottom: '100px', display: { md: "block", xxs: 'none' }, }}>
            {values.body}
          </Box>

          <Box
            item
            sx={{
              position: { sm: 'relative', xxs: "fixed" },
              bottom: { sm: "20px", xxs: "30px" },
              mt: { xxs: 5, md: 0 },
            }}
            className="cardFooter"
          >
            {"Go back to "}
            <Link
              href={(values.isEmailConfirmed && !values.error && window.innerWidth > 900) ? "/" : "/landing"}

              variant="body2"
              className='cardLink'
            >
              {"Home"}
            </Link>
          </Box>
        </Box>
      </Container>
    </>
  );
}