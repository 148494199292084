import * as React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/Flxibl_logo.png";
import "./signup.scss";
import {
  Box,
  Button,
  Card,
  CardActions,
  Container,
  CssBaseline,
  GlobalStyles,
  Grid,
  Typography,
} from "@mui/material";
import CheckBox from "./CheckBox";

const tiers = [
  {
    subheader: "All the basics for businesses that are just getting started.",
    title: "Freelancer",
    link: "/signup/freelancer",
    bgColor: "#FFFFFF",
    bodyColor: "#595F69",
    subTitle3: "Custom designs & features",
  },
  {
    subheader: "All the basics for businesses that are just getting started.",
    title: "Booker",
    link: "/signup/company",
    bgColor: "#009EB3",
    bodyColor: "white",
    subTitle3: "Custom instructors",
  },
];

function Menu() {
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />

      <div
        className="signup-background"
        style={{
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Link to={"/landing"} >
              <img className="mainLogo" src={logo} alt={"logo"} />
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to={"/login"} className="loginText">
              Login
            </Link>
          </Grid>
        </Grid>
        <Typography
          className="signupHeader"
          align="center"
          varient="h2"
          sx={{
            fontSize: { xl: "46px", md: "32px", sm: "28px", xs: "3.5rem" },
            color: "#3F434A",
            width: { sm: "50%", xxs: "76%" },
            margin: { sm: "9px auto", xxs: "20px auto", xs: "20px auto" },
            letterSpacing: "0",
          }}
        >
          Focus on your work, not your admin
        </Typography>
        <Container maxWidth="983px" component="main">
          <Grid container alignItems="flex-end" sx={{ flexDirection: { sm: "row", xs: "column-reverse" } }}>
            {tiers.map((tier) => (
              <Grid item key={tier.title} xs={12} sm={6} md={6} sx={{}}>
                <Card
                  className="cardShadow"
                  sx={{
                    float: {
                      md: tier.title === "Freelancer" ? "right" : "left",
                    },
                    margin:
                      tier.title === "Freelancer"
                        ? { md: "11px 58px 49px 0", sm: "0px 10px 34px 0px" }
                        : { md: "11px 0 49px 58px", sm: "0px 0px 34px 10px" },
                    borderRadius: "15px",
                    backgroundColor: "#fff",
                    height: { xl: "427px", sm: "380px", md: "380px", xs: "406px", xxs: "406px" },
                    width: { xl: "406px", md: "336px" },
                    marginBottom: "50px",
                  }}
                >
                  <Box sx={{ paddingLeft: "37px" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: { xl: "38px", sm: "28px", xs: "3.5rem", xxs: "3.3rem" },
                        fontWeight: { sm: "600", md: "600", xs: "600", xxs: "600" },
                        marginBottom: "22px",
                        marginTop: "35px",
                        textAlign: "center",
                        marginRight: "37px",
                        color:
                          tier.title === "Freelancer" ? "#3F434A" : "#3F434A",
                      }}
                    >
                      {tier.title}
                    </Typography>
                    <CheckBox
                      title={tier.title}
                      bgColor="white"
                      bodyColor="white"
                    />
                  </Box>
                  <CardActions style={{ justifyContent: "center" }}>
                    <Button
                      fullWidth
                      variant={"contained"}
                      href={tier.link}
                      sx={{
                        height: "35px",
                        borderRadius: "8px",
                        backgroundColor: "#304FFD",
                        maxWidth: { sm: "100%" },
                        width: "auto",
                        padding: "20px 23px"
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xl: "16px", sm: "15px", md: "15px", xs: "15px", xxs: "15px" },
                          fontWeight: "500",
                          color: "white",
                        }}
                      >
                        {tier.title === "Freelancer"
                          ? "Sign up as a freelancer"
                          : "Sign up as a company"}
                      </Typography>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
            <Grid
              container
              className="footerContainer"
              sx={{
                position: "relative",
                bottom: { md: "20px", sm: "15px", xxs: "8px" },
                // display: values.windowHeight <= 430 ? 'none' : 'flex'
              }}
            >
            </Grid>
          </Grid>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Button href="/login" className="font-14" style={{ margin: "0 auto", marginTop: "-24px" }}>
              <Typography style={{ color: "#3F434A" }} className="signupText">Already a user? </Typography>
              <Typography
                sx={{ fontSize: "15px", fontWeight: "400", color: "#304FFD" }}
              >
                Login
              </Typography>
            </Button>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default function SignUp() {
  return <Menu />;
}
