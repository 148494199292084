import * as React from "react";
import { Button, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../../assets/Flxibl_logo.png";
import "./landing.scss";
export default class LandingScreen extends React.Component {
  render() {
    return (
      <>
        <div
          className="backgroundImage"
          style={{
            overflowY: "auto",
          }}
        >
          <div style={{ height: "100vh" }}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Link to={"/"} >
                  <img className="mainLogo" src={logo} alt={"logo"} />
                </Link>
              </Grid>
              <Grid item xs={4}>
                <Link to={"/login"} className="loginText">
                  Login
                </Link>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <div className="parentBox">
                  <div className="leftBox">
                    <Typography
                      className="mainTextLanding"
                      variant="h1"
                      component="h1"
                      sx={{
                        fontWeight: "700",
                        lineHeight: "1.47",
                        fontSize: { xl: "6rem", md: "5rem", sm: "4rem", xs: "3rem" }
                      }}
                    >
                      Your favorite freelancers at your fingertips
                    </Typography>
                    <Typography
                      variant="p"
                      component="p"
                      className="mainsubLanding"
                      sx={{ marginTop: "20px", fontSize: { xl: "34px", xxl: "34px" } }}
                    >
                      Book your favorite freelancers instantly and effortlessly
                    </Typography>
                    <Button
                      fullWidth
                      variant={"contained"}
                      href="/signup"
                      sx={{
                        height: { xl: "56px", md: "40px", sm: "40px", xs: "40px", xxs: "40px" },
                        padding: "6px 60px",
                        backgroundColor: "#304FFD",
                        maxWidth: { sm: "50%", md: "80%", xxs: "100%", xs: "100%" },
                        marginTop: "20px",
                        borderRadius: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xl: "24px", md: "19px", sm: "19px", xs: "19px", xxs: "19px" },
                          fontWeight: "600",
                          color: "white",
                        }}
                      >
                        Sign up for free
                      </Typography>
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={7}>
                <div className="iframeParent">
                  <iframe
                    className="tabletIframe"
                    frameBorder="0"
                    src={"https://www.youtube.com/embed/90WsVK4hwFs?modestbranding=1"}
                    allowFullScreen
                    title="How it works"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}
