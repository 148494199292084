import * as React from "react";
import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Box, Container, CssBaseline, Link, Typography, Alert } from "@mui/material";
import { AddFreelancerToComapany } from "../Api";
import { setSlug } from '../utils';

export default function ShareAbleLink() {
  const isLoggedInUser = localStorage.getItem('loginToken');
  let { slug } = useParams();
  const [values, setValues] = React.useState({
    error: null,
    errorMsg: '',
    showAlert: false,
    isLoggedIn: isLoggedInUser ? true : false,
    title: isLoggedInUser ? 'Authenticating' : 'Please Log In',
    body: isLoggedInUser ? 'Please Wait...' :
      (React.createElement('p', { className: 'invitaitionCardBody', id: 'invitaitionCardBody' }, "To add this freelancer to your network, please ",
        React.createElement('a', { href: '/login', className: 'invitaitionCardLink' }, 'click here'), " to login or ",
        React.createElement('a', { href: '/signup/company', className: 'invitaitionCardLink' }, 'click here'), " to sign up first.",
        React.createElement('p', { className: 'invitaitionCardBody innerParagraph' }, 'If you have any problems, please contact us at '), "",
        React.createElement('span', { className: 'invitaitionCardLink' }, 'support@flxibl.com.')
      )),
  });

  if (isLoggedInUser == null) {
    setSlug(`${slug}`);
  }

  React.useLayoutEffect(() => {
    values.title = React.createElement('h1', {}, '');
    values.body = React.createElement('p', {}, '');
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (window.location.origin.includes('https://www.flxibl.io')) {
      window.location.replace("https://www.flxibl.com" + window.location.pathname);
    }
    if (values.isLoggedIn)
      AddFreelancerToComapany(values, setValues, slug);

  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (values.error === false && window.innerWidth > 900) {
      setTimeout(() => {
        window.location = "/";
      }, [5000]);
    }
  }, [values.error]);

  return (
    <>
      {values?.showAlert && <Alert onClose={() => { setValues({ ...values, showAlert: false }) }} className="alert" variant="filled" severity={values?.error ? "error" : "success"}>
        {values?.errorMsg}
      </Alert>}
      <div className='login-background'>
        <Container
          component="main"
          className='cardContainer'
          sx={{
            width: { md: "600px", xl: "600px", sm: "90%", xxs: "100%" },
            maxWidth: {
              xxs: '100%',
              xs: "100%",
              sm: "480px",
              md: "480px",
              lg: "500px",
              xl: "520px"
            },
          }}
        >
          <CssBaseline />
          <Box className="cardBox" sx={{ paddingX: "25px" }}>
            <Typography
              component="h1"
              variant="h4"
              className="cardHeader"
              sx={{
                pt: { sm: "91px", xxs: 0 },
                display: { md: "none" },
                pb: "36px",
                width: { md: "100%", sm: "80%", xxs: "90%" },
              }}
            >
              You’re in the queue..
            </Typography>
            <Typography
              component="h1"
              variant="h6"
              className="cardDescription"
              sx={{
                display: { md: "none" },
                pb: { md: "100px", xxs: "47px" },
                paddingX: "20px",
              }}
            >
              Thanks for your interest in Flxibl. Due to exceptionally high demand, we’ve had to temporarily limit the number of companies that can sign up. Someone from our team will review your account and email you directly if we’re able to grant you exclusive first access.
            </Typography>
            <Typography
              component="p"
              variant="p"
              className="cardDescription"
              sx={{
                display: { md: "none" },
                pb: { md: "100px", xxs: "47px" },
                paddingX: "20px",
              }}
            >
              Thanks for your patience, it’ll be worth the wait!
            </Typography>
            <Typography
              component="h1"
              variant="h4"
              className="cardHeader"
              sx={{
                pt: { sm: "51px", xxs: 0 },
                pb: "36px",
                display: { md: "block", xxs: 'none' },
                width: { md: '100%', sm: "80%", xxs: '90%' },
              }}
            >
              {values.title}
            </Typography>

            <Box sx={{ paddingBottom: '100px', display: { md: "block", xxs: 'none' }, }}>
              {values.body}
            </Box>

            <Box
              item
              sx={{
                position: { sm: 'relative', xxs: "fixed" },
                bottom: { sm: "20px", xxs: "30px" },
                mt: { xxs: 5, md: 0 },
              }}
              className="cardFooter"
            >
              {"Go back to "}
              <Link
                href={(values.isLoggedIn && !values.error && window.innerWidth > 900) ? "/" : "/landing"}
                variant="body2"
                className='cardLink'
              >
                {"Home"}
              </Link>
            </Box>
          </Box>
        </Container>
      </div>
    </>
  );
}