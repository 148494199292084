import * as React from 'react';
import { useEffect } from 'react';
import "./forgot.css"
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import logo from "../../assets/Flxibl_logo.png";
import { apiClient } from "../utils";

import { CssBaseline, TextField, Grid, Box, Typography, Container, Alert } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';


export default function ForgotPassowrd() {
  const [values, setValues] = React.useState({
    email: '',
    error: false,
    msg: '',
    emailMsg: '',
    isLoading: false,
    showAlert: false,
    windowHeight: window.innerHeight,
  });
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {

    if (window.location.origin.includes('https://www.flxibl.io')) {
      window.location.replace("https://www.flxibl.com" + window.location.pathname);
    }

    function handleWindowResize() {
      setValues((values) => {
        return ({
          ...values,
          windowHeight: window.innerHeight,
        })
      });
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const onBlurField = (prop) => (event) => {
    handleValidation(prop, event.target.value);
  };

  const handleValidation = (fieldName, value) => {
    switch (fieldName) {
      case 'email':
        if (/.+@.+\.[A-Za-z]+$/.test(value)) {
          setValues((values) => {
            return ({
              ...values,
              email: value,
              emailMsg: '',
            })
          });
        }
        else {
          setValues((values) => {
            return ({
              ...values,
              email: value,
              emailMsg: value === '' ? 'Email is blank' : 'Invalid email',
            })
          });
        }
        break;
      default:
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const form = document.getElementsByClassName("resetPassword")[0]
    const body = {
      user: {
        email: data.get('email')?.toLocaleLowerCase()
      }
    }
    let allEntered = data.get('email') !== "";
    let noError = values.emailMsg === "";
    if (allEntered && noError) {
      setValues({ ...values, error: false, isLoading: true });
      apiClient.post(`${process.env.REACT_APP_ROUTE}/users/password.json`, body, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        mode: 'cors',
        credentials: 'include'
      })
        .then(function (response) {
          if (response.data.error === false) {
            setValues({ ...values, error: false, msg: response.data.msg, isLoading: false, showAlert: true })
            form?.reset();
          } else {
            setValues({ ...values, error: true, msg: response.data.msg, isLoading: false, showAlert: true });
            form?.reset();
          }
        })
        .catch(function (error) {
          console.log(error);
          setTimeout(
            () => setValues({ ...values, error: true, msg: error.message, isLoading: false, showAlert: true }),
            1200
          );
        });
    } else {
      handleValidation('email', data.get('email'));
    }
  };

  return (
    <>
      {values?.showAlert && <Alert onClose={() => { setValues({ ...values, showAlert: false }) }} className="alert" variant="filled" severity={values?.error ? "error" : "success"}>
        {values?.msg}
      </Alert>}
      <div
        className="forgot-background"
        style={{
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Link to={"/"} >
              <img className="mainLogo" src={logo} alt={"logo"} />
            </Link>
          </Grid>
          <Grid item xs={6}>
            <Link to={"/login"} className="loginText">
              Login
            </Link>
          </Grid>
        </Grid>
        <Container component="main"
          sx={{
            maxWidth: {
              xxs: '90%',
              xs: "90%",
              sm: "480px",
              md: "480px",
              lg: "500px",
              xl: "520px"
            },
            paddingX: 0,
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: { sm: 8 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingX: { sm: 5, xs: 5, xxs: 3 },
              paddingY: { sm: 5, xxs: 5 },
              background: "white",
              borderRadius: 6,
            }}
          >
            <Typography align='center'
              sx={{ fontWeight: 500, fontSize: "27px", width: { xxs: "198px", sm: "100%", color: '#3F434A' }, lineHeight: "30px" }}>
              Forgot Password
            </Typography>
            <Box component="form" className="resetPassword" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                label="Email"
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                autoComplete="username"
                className="email"
                helperText={values.emailMsg}
                // onChange={handleChange('email')}
                onBlur={onBlurField('email')}
                InputProps={{ disableUnderline: true, autoComplete: "off" }}
                InputLabelProps={{ shrink: true, style: { fontSize: 14, color: "#8A9099" } }}
              />

              <LoadingButton
                type="submit"
                fullWidth
                loading={values.isLoading}
                variant="contained"
                className='login-btn'
                sx={{ mt: 3, mb: 2 }}
              >
                Reset
              </LoadingButton>

              <Grid container className='footerContainer'
                sx={{
                  position: { sm: 'relative', xxs: "fixed" },
                  // backgroundColor: { xxs: '#fff', sm: 'transparent' },
                  bottom: { sm: 0, xxs: "10px" },
                  display: values.windowHeight <= 430 ? 'none' : 'flex'
                }}
              >
              </Grid>
            </Box>
          </Box>
        </Container>
      </div>
    </>
  );
}