import * as React from 'react';
import { Menu, Box, Link } from '@mui/material';
import InvitationDialog from './InvitationDialog';
import { logout } from "../utils"
import userPlus from "../../assets/user_plus.png"
import chat from "../../assets/chat.png"
import logOutImage from "../../assets/logout.png"


const MenuDialog = (props) => {

  var invitation_count = localStorage.getItem("invitation_count");
  var invitationCountStatus = (invitation_count === 0 || invitation_count === null || invitation_count === undefined);
  const [open, setOpen] = React.useState(invitationCountStatus);
  const openInvitationDialog = () => {
    props.setOpen();
    setOpen(!open);
  };

  const logoutClick = () => {
    localStorage.removeItem("color_code")
    localStorage.removeItem("user_type")
    localStorage.removeItem("resource_id")
    localStorage.removeItem("phone")
    localStorage.removeItem("invitation_count")
    logout()
    window.location = "/login"
  }
  return (
    <>
      <Menu
        sx={{ mt: '40px', height: props.isPwa ? '250px' : '120px', width: props.isPwa ? "100%" : '200px' }}
        id={`menu-appbar${props.isPwa ? "" : "fixed"}`}
        anchorEl={props.open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(props.open)}
        onClose={props.setOpen}
      >
        <Box
          sx={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}
        >
          {props.isPwa && <div className='settings bottom-border settings-height'>
            Settings
          </div>}
          <div className='inviteFree' onClick={() => { openInvitationDialog(); }}>
            <Link className='menuLink' > Invite Freelancers { props.isPwa && <img className='img-settings' src={userPlus} alt="img-settings" /> }</Link>
            {props.isPwa && <span className='tagLine'>Add new freelancers
              </span> }
           
          </div>
          {props.isPwa && <div className='whatApp'>
            <Link className='menuLink' 
              href={`https://api.whatsapp.com/send/?phone=%2B${process.env.REACT_APP_FLXIBLE_SUPPORT}&text=${"hello"}&type=phone_number&app_absent=0`} target='_blank' >Chat with support { props.isPwa &&  <img className='img-settings' src={chat} alt="img-settings" /> } </Link>
              <span className='tagLine'>Ask questions or report bugs
              </span> 
          </div>}
          <div className='logoutComp' onClick={() => logoutClick()}>
            <Link className='menuLink' >Logout { props.isPwa &&  <img className='img-settings' src={logOutImage} alt="img-settings" /> } </Link>
            {props.isPwa && <span className='tagLine'>Logout of your account
              </span> }
          </div>


        </Box>
      </Menu>
      {open && <InvitationDialog open={open} setOpen={setOpen} />}
    </>
  )
};
export default MenuDialog;