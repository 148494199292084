import React, { useEffect } from 'react';
import { Box, Container, CssBaseline, Link, Typography, Alert } from "@mui/material";
import { AcceptInviteApi } from "../Api";

export default function Invite() {
  const [values, setValues] = React.useState({
    error: null,
    errorMsg: '',
    showAlert: false,
    title: '',
    body: '',
  });
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  React.useLayoutEffect(() => {
    values.title = React.createElement('h1', {}, '');
    values.body = React.createElement('p', {}, '');
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (window.location.origin.includes('https://www.flxibl.io')) {
      window.location.replace("https://www.flxibl.com" + window.location.pathname);
    }
    AcceptInviteApi(window.location.search, values, setValues)
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  setTimeout(() => {
    if (values.errorMsg === 'Invitation accepted' || values.errorMsg === 'Invitation already accepted') {
      window.location = `/signup/freelancer?__token=${params.__token}`;
    }
  }, [5000]);

  return (
    <>
      {values?.showAlert && <Alert onClose={() => { setValues({ ...values, showAlert: false }) }} className="alert" variant="filled" severity={values?.error ? "error" : "success"}>
        {values?.errorMsg}
      </Alert>}
      <div className='login-background'>
        <Container
          component="main"
          className='cardContainer'
          sx={{
            width: { md: "600px", xl: "600px", sm: "90%", xxs: "100%" },
            maxWidth: {
              xxs: '100%',
              xs: "100%",
              sm: "480px",
              md: "480px",
              lg: "500px",
              xl: "520px"
            },
          }}
        >
          <CssBaseline />
          <Box className='cardBox' sx={{ paddingX: "15px",width:{xxs:"90%",xs:"90%",sm:"100%"} }}>
            <Typography
              component="h1"
              variant="h4"
              className="cardHeader"
              sx={{
                pt: { sm: "51px", xxs: 0 },
                pb: "36px",
                width: { md: '100%', sm: "80%", xxs: '90%' },
              }}
            >
              {values.title}
            </Typography>

            <Box sx={{ paddingBottom: '100px' }}>
              {values.body}
            </Box>
            <Box
              item
              sx={{
                position: { sm: 'relative', xxs: "fixed" },
                bottom: { sm: "20px", xxs: "30px" },
                mt: { xxs: 5, md: 0 },
              }}
              className="cardFooter"
            >
              {"Go back to "}
              <Link
                href={(values.error) ? "/login" : `/signup/freelancer?__token=${params.__token}`}
                variant="body2"
                className='cardLink'
              >
                {"Home"}
              </Link>
            </Box>
          </Box>
        </Container>
      </div>
    </>
  );
}