import * as React from 'react';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import bell from '../../assets/bell.png'
import notificationBell from '../../assets/notificationBell.png'
import menuImg from '../../assets/Menu.png'
import NotificationsDialog from './NotificationsDialog';
import MenuDialog from './MenuDialog';
import { GetNotificationListApi, MarkReadToNotificationsApi } from '../Api';
let wasOpened = false;

export default function Navbar({ newNotificationArrived, handleNewNotificationArrived }) {

  const [values, setValues] = React.useState({
    showNotificationMenu: false,
    showMenuDialog: false,
    newNotificationsCount: 0,
    notifications: [],
    total: 0,
    renderCall: true,
    error: false,
    errorMsg: "",
  });

  useEffect(() => {
    if (values.showNotificationMenu) {
      wasOpened = true;
    }
    else if (wasOpened) {
      wasOpened = false;
      if (newNotificationArrived) {
        MarkReadToNotificationsApi(values, setValues, handleNewNotificationsCount);
        handleNewNotificationArrived(false);
      }
      return
    }
    GetNotificationListApi(values, setValues, handleNewNotificationsCount, handleNewNotificationArrived);  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.showNotificationMenu]);

  const handleNewNotificationsCount = (count) => {
    setValues({ ...values, newNotificationsCount: count });
  }

  const handleOpenNotificationMenu = () => {
    setValues({ ...values, showNotificationMenu: !values.showNotificationMenu });
  };

  const handleOpenMenuDialog = () => {
    setValues({ ...values, showMenuDialog: !values.showMenuDialog });
  };

  return (
    <Box >
      <div id="logout-button">
        <img src={menuImg} className='menuIcon' style={{ backgroundColor: values.showMenuDialog ? '#F8F8F8' : 'white' }} onClick={handleOpenMenuDialog} alt="Logo" />
        <img src={(newNotificationArrived) ? notificationBell : bell} className='bellIcon' style={{ backgroundColor: values.showNotificationMenu ? '#F8F8F8' : 'white' }} onClick={handleOpenNotificationMenu} alt="bellIcon" />
      </div>
      {values.showNotificationMenu &&
        <NotificationsDialog
          open={values.showNotificationMenu}
          setOpen={handleOpenNotificationMenu}
          notifications={values.notifications}
          values={values}
          setValues={setValues}
          total={values.total}
          handleNewNotificationsCount={handleNewNotificationsCount}
          newNotificationsCount={values.newNotificationsCount}
          handleNewNotificationArrived={handleNewNotificationArrived}
        />}
      <MenuDialog open={values.showMenuDialog} isPwa={window.innerWidth > 900 ? false : true} setOpen={handleOpenMenuDialog} />
    </Box>
  );
};