import * as React from 'react';
import { useEffect } from 'react';
import "./confirm.css"
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { CssBaseline, TextField, Grid, Box, Typography, Container, IconButton, InputAdornment, Alert } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';



export default function ConfirmPassword() {
  const [values, setValues] = React.useState({
    password: '',
    confirmpassword: '',
    error: false,
    msg: '',
    showAlert: false,
    passwordMsg: '',
    confirmPasswordMsg: '',
    isLoading: false,
    showPassword: false,
    showConfirmPassword: false,
    windowHeight: window.innerHeight,
  });
  useEffect(() => {

    if (window.location.origin.includes('https://www.flxibl.io')) {
      window.location.replace("https://www.flxibl.com" + window.location.pathname);
    }

    function handleWindowResize() {
      setValues((values) => {
        return ({
          ...values,
          windowHeight: window.innerHeight,
        })
      });
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const onBlurField = (prop) => (event) => {
    handleValidation(prop, event.target.value);
  };

  const handleValidation = (fieldName, value) => {
    switch (fieldName) {
      case 'password':
        if (value.length > 0) {
          setValues((values) => {
            return ({
              ...values,
              password: value,
              passwordMsg: '',
            })
          });
        }
        else {
          setValues((values) => {
            return ({
              ...values,
              password: value,
              passwordMsg: 'Password is blank',
            })
          });
        }
        break;
      case 'confirm-password':
        if (value.length > 0 && values.password === values.confirmpassword) {
          setValues((values) => {
            return ({
              ...values,
              confirmpassword: value,
              confirmPasswordMsg: '',
            })
          });
        }
        else {
          setValues((values) => {
            return ({
              ...values,
              confirmpassword: value,
              confirmPasswordMsg: passwordErrorMessage(values.password, value),
            })
          });
        }
        break;
      default:
    }
  }

  const passwordErrorMessage = (password, confirmPassword) => {
    if (confirmPassword.length <= 0) {
      return 'Confirm Password is blank';
    }
    else if (password !== confirmPassword) {
      return 'Passwords do not match';
    }
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    const form = document.getElementsByClassName("confirmPassword")[0]
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const data = new FormData(event.currentTarget);
    const body = {
      user: {
        email: params.__email,
        reset_password_token: params.__token,
        password: data.get('password')
      }
    }
    let allEntered = data.get('confirm-password') !== "" && data.get('password') !== "";
    let noError = values.passwordMsg === "" && (values.confirmPasswordMsg === "" || values.confirmPasswordMsg === undefined);
    if (allEntered && noError) {
      setValues({ ...values, error: false, isLoading: true });
      axios.put(`${process.env.REACT_APP_ROUTE}/users/password.json`, body, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        mode: 'cors',
        credentials: 'include'
      }).then(function (response) {
        if (response.data.error === false) {
          setValues({ ...values, error: false, msg: response.data.msg, isLoading: false, showAlert: true });
          form?.reset();
          setTimeout(
            () => window.location = '/login',
            1200
          );

        } else {
          setValues({ ...values, error: true, msg: response.data.msg, isLoading: false, showAlert: true });
          form?.reset();
        }
      }).catch(function (error) {
        console.log(error);
        setTimeout(
          () => setValues({ ...values, error: true, msg: error.message, isLoading: false, showAlert: true }),
          1200
        );
      });

    } else {
      handleValidation('password', data.get('password'));
      handleValidation('confirm-password', data.get('confirm-password'));
    }
  };

  return (
    <>
      {values?.showAlert && <Alert onClose={() => { setValues({ ...values, showAlert: false }) }} className="alert" variant="filled" severity={values?.error ? "error" : "success"}>
        {values?.msg}
      </Alert>}

      <Container component="main"
        sx={{
          maxWidth: {
            xxs: '100%',
            xs: "100%",
            sm: "480px",
            md: "480px",
            lg: "500px",
            xl: "520px"
          },
          paddingX: 0,
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            marginTop: { sm: 8 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingX: { sm: 5, xs: 5, xxs: 3 },
            paddingY: { sm: 5, xxs: 5 },
            background: "white",
            borderRadius: { sm: 6 },
            height: { xxs: window.innerHeight, sm: '100%' },
          }}
        >
          <Typography align='center'
            sx={{ fontWeight: 500, fontSize: "27px", width: { xxs: "198px", sm: "100%", color: '#3F434A' }, lineHeight: "30px" }}>
            Reset Your Password
          </Typography>
          <Box component="form" className="confirmPassword" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

            <TextField
              label="Password"
              margin="normal"
              variant="standard"
              required
              fullWidth
              name="password"
              type={values.showPassword ? 'text' : 'password'}
              // onChange={handleChange('password')}
              onBlur={onBlurField('password')}
              id="password"
              className="password"
              autoComplete="current-password"
              helperText={values.passwordMsg}
              InputLabelProps={{ shrink: true, style: { fontSize: 14, color: "#8A9099" } }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment className='togglePassword' position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => { setValues({ ...values, showPassword: !values.showPassword }) }}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Confirm Password"
              margin="normal"
              variant="standard"
              required
              fullWidth
              name="confirm-password"
              type={values.showConfirmPassword ? 'text' : 'password'}
              // onChange={handleChange('password')}
              onBlur={onBlurField('confirm-password')}
              id="password"
              className="password"
              autoComplete="current-password"
              helperText={values.confirmPasswordMsg}
              InputLabelProps={{ shrink: true, style: { fontSize: 14, color: "#8A9099" } }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment className='togglePassword' position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => { setValues({ ...values, showConfirmPassword: !values.showConfirmPassword }) }}
                    >
                      {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />


            <LoadingButton
              type="submit"
              fullWidth
              loading={values.isLoading}
              variant="contained"
              className='login-btn'
              sx={{ mt: 3, mb: 2 }}
            >
              Reset Password
            </LoadingButton>

            <Grid container className='footerContainer'
              sx={{
                position: { sm: 'relative', xxs: "fixed" },
                // backgroundColor: { xxs: '#fff', sm: 'transparent' },
                bottom: { sm: 0, xxs: "10px" },
                display: values.windowHeight <= 430 ? 'none' : 'flex'
              }}
            >
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}