export const validator = (values, fieldName) => {
  let errors = {};
  switch (fieldName) {
    case "companyName":
      validateCompanyName(values.companyName, errors);
      break;
    case "name":
      validateName(values.name, errors);
      break;
    case "email":
      validateEmail(values.email, errors);
      break;
    case "password":
      validatePassword(values.password, errors);
      break;
    case "confirmPassword":
      validateConfirmPassword(values.password, values.confirmPassword, errors);
      break;
    case "termCondition":
      validateTermCondition(values.termCondition, errors);
      break;
    case "profession":
      validateProfession(values, errors);
      break;
    // case "phone":
    //   validatePhone(values.phone, errors);
    //   break;
    case "rate":
      validateRate(values.rate, errors);
      break;
    case "projectName":
      validateProjectName(values.projectName, errors);
      break;
    case "description":
      validateDescription(values.description, errors);
      break;
    case "addLocation":
    case "location":
      validateLocation(values, errors);
      break;
    default:
  }
  return errors;
};

function validateCompanyName(companyName, errors) {
  let result = true;

  if (!companyName) {
    errors.email = "Company name is Required";
    result = false;
  } else {
    const re = /^[a-z ,.'-]+$/i;
    result = re.test(String(companyName).toLowerCase());
    if (!result) errors.companyName = "Invalid company name";
  }
  return result;
}

function validateName(name, errors) {
  let result = true;

  if (!name) {
    errors.name = "Name is Required";
    result = false;
  } else {
    const re = /^[a-z ,.'-]+$/i;
    result = re.test(String(name).toLowerCase());
    if (!result) errors.name = "Invalid Name";
  }
  return result;
}

function validateEmail(email, errors) {
  let result = true;

  if (!email) {
    errors.email = "Email is Required";
    result = false;
  } else {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;  // eslint-disable-line
    result = re.test(String(email).toLowerCase());
    if (!result) errors.email = "Invalid Email address";
  }
  return result;
}

function validatePassword(pass, errors) {
  let result = true;

  if (!pass) {
    errors.password = "Password is Required";
    result = false;
  }
  else if (pass.length < 6) {
    errors.password = "Password must be atleast 6 characters";
    result = false;
  }

  return result;
}

function validateConfirmPassword(pass, confirmPass, errors) {
  let result = true;

  if (!confirmPass) {
    errors.confirmPassword = "Confirm Password is Required";
    result = false;
  } else {
    result = pass === confirmPass;

    if (!result) {
      errors.password = "Password don't match";
      result = false;
    }
  }

  return result;
}

function validateTermCondition(termCondition, errors) {
  if (!termCondition) {
    errors.termCondition = !errors.termCondition;
  }
}

function validateProfession(values, errors) {
  let result = true;

  if (!values.profession) {
    errors.profession = "Profession is Required";
    result = false;
  } else if (values.wordLimitExceeded) {
    errors.profession = "Three words max";
    result = false;
  } else {
    const re = /^[a-z ,.'-]+$/i;
    result = re.test(String(values.profession).toLowerCase());
    if (!result) errors.profession = "Invalid Profession";
  }
  return result;
}

function validateRate(rate, errors) {
  let result = true;
  if (!rate) {
    errors.rate = "Invalid rate";
    result = false;
  } else {
    const re = /^[1-9]\d*$/;
    result = re.test(String(rate).toLowerCase());
    if (!result) errors.role = "Valid rate is Required";
  }
  return result;
}

function validateProjectName(projectName, errors) {
  let result = true;

  if (!projectName) {
    errors.projectName = "Project name is Required";
    result = false;
  }
  return result;
}

function validateDescription(description, errors) {
  let result = true;

  if (!description) {
    errors.description = "Description is Required";
    result = false;
  }
  return result;
}

function validateLocation(values, errors) {
  let result = true;
  if (!values.location) {
    errors.location = "Location is Required";
    result = false;
  }
  return result;
}

export const validateAllError = (fieldArray, body, setErrors) => {
  fieldArray.map((item, index) => {  // eslint-disable-line
    const fieldName = validator(body, item);
    setErrors((errors) => {
      return ({
        ...errors,
        [item]: Object.values(fieldName)[0],
      })
    });
  });
}