import * as React from 'react';
import { useEffect, memo } from 'react';
import { Menu, ListItem, ListSubheader, Divider, Typography, Box, Grid, List } from '@mui/material';
import { GetNotificationListApi } from '../Api';
import moment from 'moment';

const NotificationsDialog = (props) => {
  const [values] = React.useState({
    error: false,
    errorMsg: "",
  });

  useEffect(() => {
    GetNotificationListApi(props.values, props.setValues, props.handleNewNotificationsCount, props.handleNewNotificationArrived);
  }, [values.errorMsg]);  // eslint-disable-line react-hooks/exhaustive-deps


  function notificationText(type, date) {
    switch (type) {  // eslint-disable-line default-case
      case 'Accept Booking':
        return (`Accepted your booking on ${date}`);
      case 'Cancel Booking':
        return (`Cancelled your booking on ${date}`);
      case 'Withdrawn Pending Booking':
        return (`Declined your booking on ${date}`);
      case 'Freelancer Unavailable':
        return (`Declined your booking on ${date}`);
      case 'Invite':
        return ('Accepted your invite');
      case 'Cancel Option':
        return (`Declined your option on ${date}`);
    }
  }
  function matchCurrentDate(date) {
    if (moment(date).format('DD:MM:YYYY') === moment(new Date()).format('DD:MM:YYYY')) {
      return true;
    }
    else {
      return false;
    }
  }

  return (
    <Menu
      sx={{ mt: '40px', display: 'flex', borderRadius: '10px' }}
      id="menu-appbar"
      anchorEl={props.open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={Boolean(props.open)}
      onClose={props.setOpen}
      className="notificationPopup"
    >
      <Box className='dropdownMenu'>
        <Box className='dropdownMenuHeaderContainer'>
          <Box className='dropdownMenuHeader' >
            <Typography className='dropdownMenuHeading' >Notifications</Typography>
            {props.newNotificationsCount > 0 ?
              <Typography className='norificationsCount' textAlign="center">{props.newNotificationsCount}</Typography>
              : <></>
            }
          </Box>
          <Divider />
        </Box>

        <Box sx={{ height: 50 }}></Box>

        {(props.total > 0) ?
          <List
            sx={{
              width: '100%',
              maxWidth: 'auto',
              bgcolor: 'background.paper',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 'auto',
              '& ul': { padding: 0 },
            }}
            subheader={<li />}
          >
            {props.notifications.map((item, index) => (  // eslint-disable-line
              <li key={index}>
                <ul>
                  {
                    (index === 0 ||
                      props.notifications[index - 1]?.date !== props.notifications[index]?.date
                    ) &&
                    <ListSubheader>
                      <Typography textAlign="center" className='notificationDateTitle' >{matchCurrentDate(props?.notifications[index]?.created_at) ? 'Today' : props.notifications[index]?.date}</Typography>
                    </ListSubheader>
                  }
                  <ListItem key={`item-${index}`} sx={{ backgroundColor: !props.notifications[index]?.read && '#F8F8F8' }} className="singleNotification">
                    <Grid container className='notificationContent'>
                      <Grid item className='' xs={12}  >
                        <Typography textAlign="flex-start" className='senderName' sx={{ fontWeight: props.notifications[index]?.read ? 500 : 600 }}>{props.notifications[index]?.sender_name ? props.notifications[index]?.sender_name : "Deleted User"}</Typography>
                      </Grid>
                      <Grid item className='' xs={12}  >
                        <Typography textAlign="flex-start" className='senderStatus'  >{notificationText(props.notifications[index]?.payload.type, props.notifications[index]?.payload.date)}</Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                </ul>
              </li>
            ))}
          </List> :
          <Typography
            sx={{ mt: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '12px' }}
          >No notification to show </Typography>
        }
      </Box>
    </Menu >
  );
};
export default memo(NotificationsDialog);