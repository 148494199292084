const Support = () => {
  return (
    <div >
      <h3>
        Support
      </h3>
      If you have any questions, issues, feedback or if you'd like to suggest feature enhancements please email us on <span style={{ color: "blue" }}>info@flxibl.io</span> A member of our team will respond to you within 48 hours.
    </div>
  );
};
export default Support;