import './blocker.css';

export const Blocker = () => (
  <div className="blocker">
    <h1 className="blocker_h1">It looks like you're on a mobile?</h1>
    <p className="blocker_p">Please login on desktop for the best experience.</p>
    <p className="blocker_p">(Mobile updates coming soon)</p>
  </div>
)

export default Blocker;
