import * as React from "react";
import { Box, Container, CssBaseline, Link, Typography } from "@mui/material";
import OpenApp from "react-open-app";

export default function VerifyEmail() {

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  return (
    <div
      className="verify-background"
      style={{
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        className="cardContainer"
        sx={{
          width: { md: "600px", xl: "600px", sm: "90%", xxs: "90%" },
          maxWidth: {
            xxs: '90%',
            xs: "100%",
            sm: "480px",
            md: "480px",
            lg: "500px",
            xl: "520px"
          },
        }}
      >
        <CssBaseline />
        <Box className="cardBox" sx={{
          padding: { sm: "0", xxs: "30px 9px" }
        }}>
          <Typography
            component="h1"
            variant="h4"
            className="cardHeader"
            sx={{
              pt: { sm: "91px", xxs: 0 },
              pb: "36px"
            }}
          >
            Check Your Email
          </Typography>
          <Typography
            component="h1"
            variant="h6"
            className="cardDescription"
            sx={{
              pb: { md: "100px", xxs: "47px" },
              paddingX: "20px",
              textAlign: { xxs: "center", sm: "left" }
            }}
          >
            We have sent you an email. Please click the link to verify your
            email address.
          </Typography>
          {getMobileOperatingSystem() === "iOS" ?
            <OpenApp href="message://" className='cardLink' sx={{ display: { md: "none" } }}>
              Open Email App
            </OpenApp>
            :
            <Link
              href="https://mail.google.com/mail"
              variant="body2"
              className='cardLink'
              sx={{ display: { md: "none" } }}
            >
              Open Email App
            </Link>
          }
          <Box
            item
            sx={{
              position: { sm: 'relative', xxs: "fixed" },
              bottom: { sm: "20px", xxs: "30px" },
              mt: { xxs: 5, md: 0 },
            }}
            className='cardFooter'
          >
            {"Go back to "}
            <Link
              href="/login"
              variant="body2"
              className='cardLink'
            >
              {"Home"}
            </Link>
          </Box>
        </Box>
      </Container>
    </div>
  );
}